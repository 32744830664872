// @flow
import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from './HeaderMerchant.module.scss';
import { merchantMenu } from './const';

const HeaderMerchant = ({
  siteTitle,
  pathname,
}: {
  siteTitle: string,
  pathname: string,
}) => {
  return (
    <div
      className={styles.headerWrapper}
      style={{
        background: `rgba(255, 255, 255, 1)`,
      }}
    >
      <header className={cx(styles.header, { [styles.whiteBg]: true })}>
        <div className={styles.headerInner}>
          <div className={styles.internals}>
            <h1 title={siteTitle} className={styles.logo}>
              <OutboundLink href="/" className="ga_click_paidy_logo">
                <img
                  className={styles.paidyLogoColor}
                  alt={siteTitle}
                  src={require('../../images/logo-color-10th.svg')}
                />
                <img
                  className={styles.paidyLogoWhite}
                  alt={siteTitle}
                  src={require('../../images/paidy-logo-white.svg')}
                />
              </OutboundLink>
            </h1>
            <nav className={cx(styles.mainNav, styles.merchant)}>
              {merchantMenu.map((menuItem) =>
                menuItem.link ? (
                  <div
                    className={cx(styles.linkBox, {
                      [styles.active]: menuItem.subMenu
                        ? pathname.startsWith(menuItem.link)
                        : pathname.endsWith(menuItem.link) ||
                          pathname.startsWith(menuItem.followLink),
                      [styles.withDropdown]: !!menuItem.subMenu,
                    })}
                    key={menuItem.link}
                  >
                    <Link to={menuItem.link}>{menuItem.title}</Link>
                    {menuItem.subMenu && (
                      <nav className={styles.subNav}>
                        {menuItem.subMenu.map((subMenuItem) =>
                          subMenuItem.link ? (
                            <Link
                              to={subMenuItem.link}
                              key={subMenuItem.link}
                              className={cx({
                                [styles.active]: pathname.endsWith(
                                  subMenuItem.link
                                ),
                              })}
                            >
                              {subMenuItem.title}
                            </Link>
                          ) : (
                            <OutboundLink
                              key={subMenuItem.extLink}
                              href={subMenuItem.extLink}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {subMenuItem.title}
                            </OutboundLink>
                          )
                        )}
                      </nav>
                    )}
                  </div>
                ) : (
                  <div className={styles.linkBox} key={menuItem.extLink}>
                    <OutboundLink
                      href={menuItem.extLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {menuItem.title}
                    </OutboundLink>
                  </div>
                )
              )}
            </nav>
          </div>
          <nav className={styles.externals}>
            <Link
              className={cx(styles.toConsumerPage, {
                [styles.active]: pathname.endsWith('/merchant/contact_us/'),
              })}
              to="/merchant/contact_us/"
            >
              導入相談
            </Link>
            <OutboundLink
              className={styles.toMerchantDashboard}
              href="https://merchant.paidy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ログイン
            </OutboundLink>
          </nav>
        </div>
      </header>
    </div>
  );
};

HeaderMerchant.defaultProps = {
  siteTitle: ``,
};

export default HeaderMerchant;
